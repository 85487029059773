import React from "react"
import { Link } from "gatsby"
import Layout from "./../components/layouts/Request"
import Seo from "./../components/layouts/Seo"
import RequestLeftCol from "./../components/layouts/partials/pages/request/RequestLeftCol"

export default function Page() {
  const svg = {
    emoji: (
      <svg
        id="emoji"
        viewBox="0 0 72 72"
        width="64"
        height="64"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="color">
          <path
            fill="#b1cc33"
            d="m61.5 23.3-8.013-8.013-25.71 25.71-9.26-9.26-8.013 8.013 17.42 17.44z"
          />
        </g>
        <g id="line">
          <path
            fill="none"
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="m10.5 39.76 17.42 17.44 33.58-33.89-8.013-8.013-25.71 25.71-9.26-9.26z"
          />
        </g>
      </svg>
    ),
  }

  return (
    <Layout>
      <Seo
        title="Newsletter-Anmeldung erfolgreich"
        description="Vielen Dank für Deine Newsletter-Anmeldung"
        path="/newsletter-anmeldung-bestaetigt/"
      />
      <section>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-8/12 lg:order-2 max-w-screen-lg p-8 lg:p-16">
            <div className="flex flex-wrap">
              <div className="w-full mb-5 lg:hidden">
                <Link to="/" className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-3"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="#1939ec"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <circle cx="12" cy="12" r="9" />
                    <line x1="8" y1="12" x2="16" y2="12" />
                    <line x1="8" y1="12" x2="12" y2="16" />
                    <line x1="8" y1="12" x2="12" y2="8" />
                  </svg>{" "}
                  Zur Startseite
                </Link>
              </div>
              <div className="w-full mb-5">
                <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-5">
                  {svg.emoji} Newsletter-Anmeldung erfolgreich
                </h1>
                <p>Vielen Dank für Deine Newsletter-Anmeldung.</p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-4/12 lg:order-1 bg-gray-100 p-8 lg:p-16 ">
            <RequestLeftCol />
          </div>
        </div>
      </section>
    </Layout>
  )
}
